/* @media (max-width: 1023px) {
}
@media (max-width: 767px) {
}
@media (max-width: 480px) {
} */

/* font */
@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);

.notosanskr * {
  font-family: 'Noto Sans KR', sans-serif;
}

@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
.press-start-2p {
  font-family: "Press Start 2P", system-ui;
  font-style: normal;
}

/* nomal */
body, ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: 'Noto Sans KR', sans-serif;
}

body * {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

/* library */
.bg-red {
  background-color: red;
}

.bg-gold {
  background-color: gold;
}

.bg-blue {
  background-color: blue;
}

.bg-gray {
  background: #f5f6f9;;
}

/* custom */
:root {
  --header-width: 1400px;
  --header-height: 80px;
  --header-sbbile-height: 50px;
  --detail-width: 1200px;
  --detail-right-width: 350px;
}

@media (max-width: 1023px) {
  :root {
    --header-height: 60px;
  }
}

:root {

  --page-width: 1200px;
  --page-padding-pc: 0 40px;
  --page-padding-tab: 0 30px;
  --page-padding-mo: 0 20px;
}

.block {
  display: block !important;
}

.none {
  display: none !important;
}

.only-pc-flex {
  display: flex !important;
}

.only-mo-flex {
  display: none !important;
}

.only-mo-block {
  display: none !important;
}

.flex {
  display: flex !important;
}

.inline {
  display: inline !important;
}

.visible {
  visibility: visible !important;
}

.overflow-visible {
  overflow: visible !important;
}

.max-h100vh {
  max-height: 100vh !important;
}

.padding-top-20px {
  padding-top: 20px !important;
}

.underline {
  text-decoration: underline !important;
}

.bold {
  font-weight: bold !important;
}

.br-pc {
  display: block;
}

.br-mo {
  display: none;
}

@media (max-width: 1023px) {
  .br-pc {
    display: none;
  }

  .br-mo {
    display: block;
  }

  .only-pc-flex {
    display: none !important;
  }

  .only-mo-flex {
    display: flex !important;
  }

  .only-mo-block {
    display: block !important;
  }
}

/* ======================================================== */

.header {
  height: var(--header-height);
  /* 평상시에 height를 초과한 서브메뉴가 안보이게 hidden */
  width: 100%;
  z-index: 500;

  position: fixed;
  background-color: #fff;
  /* left: 0; */
  /* height: 0; */
}

.header__con {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 0 3%;
  display: flex;
  justify-content: space-between;
  color: #111;
  z-index: 300 !important;
}

.header__logo-con {
  height: 100%;
  display: flex;
  align-items: center;
}


.header__logo-con > li {
  height: 100%;
}

.header__logo-con > li > a {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header__logo {
  height: 100%;
  display: flex;
  align-items: center;
}

.header__logo img {
  height: 60%;
}


.header__category-con {
  margin-left: 50px;
}

.header__category-con {
  height: 100%;
  display: flex;
  align-items: center;
}

.header__category-con > li {
  height: 100%;
  /* width: 90px; */
  margin: 0 30px;
  position: relative;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-mo__con {
  display: none;
}

.header-sb {
  display: none;
}

.header-block {
  height: 0;
  height: var(--header-height)
}

@media (max-width: 1023px) {
  .header-block {
    height: 0;
  }
}

@media (max-width: 1023px) {
  .header__con {
    display: none;
  }

  .header-mo__con {
    height: 100%;
    padding: var(--page-padding-mo);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header-mo__arrow img {
    width: 7px;
  }

  .header-mo__hamburger img {
    width: 15px;
  }

  .header-sb {
    display: block;
    position: fixed;
    width: 80%;
    height: 100vh;
    left: -110%;
    top: 0;
    transition: left .4s ease-in;
    background-color: #fff;
    z-index: 100;
  }

  .left-0 {
    left: 0;
  }

  .header-sb__category-con {
    margin-top: 50px;
  }

  .header-sb__category-con > li > a {
    width: 100%;
    border-bottom: 1px solid #d5d5d5;
  }

  .header-sb__category-con > li:last-child > a {
    border: none;
  }

  .header-sb__category-con > li > a {
    display: inline-block;
    padding: 18px 30px;
    font-size: 14px;
    font-weight: 400;
  }

  .header__contact-btn-wrap {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }

  .bg-gray {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.8);
  }
}

/* ========================================================= */

.intro {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: #FDFAFA;
}

.intro__con {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  max-width: 500px;
  width: 100%;
  padding: var(--page-padding-pc);
}

/* logo */
.intro__logo-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.intro__logo-wrap > img {
  width: 80px;
}

.intro__logo-wrap > span {
  display: inline-block;
  margin-left: 20px;
  font-size: 35px;
  line-height: 40px;
  color: #CF506F;
  font-family: "Press Start 2P", system-ui;
}

/* images */
.intro__img-con {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.intro__img-con > div {
  width: 100%;
  text-align: right;
}

.intro__img-con > div > span {
  display: inline-block;
  padding: 20px 25px;


  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.5px;
  font-weight: 400;
  font-family: 'Noto Sans KR', sans-serif;

  border-radius: 10px;
  border-bottom-left-radius: 0;
  background-color: #f4f4f4;
}

.intro__img-con > img {
  margin-top: 30px;
  max-width: 450px;
  width: 100%;
}

/* buttons */
.intro__btn-con {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.intro__btn-con > li {
  width: 200px;
  height: 60px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #fff;
  background-color: #ECC0C7;
  border-radius: 30px;
  box-shadow: 2px 2px 3px #afafaf;
}

.intro__btn-con > li > a {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.5px;
  font-weight: 400;
  /* cursor: pointer; */
}

.intro__btn-con > ul {
  margin-top: 20px;
  width: 200px;

  display: flex;
  justify-content: space-around;

  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.5px;
  font-weight: 300;
  color: #707070;
}

@media (max-width: 1023px) {
  .intro__con {
    padding: var(--page-padding-mo);
  }

  .intro__logo-wrap > img {
    width: 60px;
  }

  .intro__logo-wrap > span {
    margin-left: 10px;
    font-size: 25px;
    line-height: 25px;
  }

  /* images */
  .intro__img-con {
    margin-top: 40px;
  }

  .intro__img-con > div > span {
    padding: 16px 22px;


    font-size: 14px;
    line-height: 14px;
  }

  .intro__img-con > img {
    max-width: 350px;
  }
}

/* ========================================================= */
.userinfo {
  margin: 0 auto;
  /* max-width: var(--page-width); */
  width: 100%;
  height: calc(100vh - var(--header-height))
}

.userinfo__left-con {
  width: 30vw;
  height: 100%;


  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-right: 1px solid rgba(217, 217, 217, 1);
}

.userinfo__left-img {
  width: 120px;
  aspect-ratio: 1/1;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(235, 221, 231, 0.49);
  border-radius: 50%;
  box-shadow: 0px 2px 3px #afafaf;
}

.userinfo__left-img > img {
  width: 60%;
}

.userinfo__left-img-txt {
  margin-top: 30px;
  display: flex;
  justify-content: center;

  font-size: 22px;
  line-height: 22px;
  font-weight: 600;
}

.userinfo__churr-wrap {
  margin-top: 30px;
  display: flex;
  align-items: center;
  height: 50px;
}

.userinfo__churr-wrap > img {
  margin-right: 15px;
  height: 100%;
  object-fit: contain;
}

.userinfo__churr-wrap > li > span {
  margin-left: 15px;
  color: rgba(207, 80, 111, 1);
}

.userinfo__churr-wrap-mo {
  display: none;
}

.userinfo__charge-con {
  margin-top: 80px;
}

.userinfo__charge-con > li {
  width: 120px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  letter-spacing: -1px;
  border-radius: 20px;
  color: #fff;
  background-color: rgba(207, 80, 111, 1);
  cursor: pointer;

  position: relative;
}

.userinfo__charge-con > li > img {
  position: absolute;
  top: -40px;
  right: 10px;
  width: 35px;
}

.userinfo__charge-con > li:nth-child(2) {
  margin-top: 15px;
  background-color: rgba(243, 137, 38, 1);
}

.userinfo__right-con {
  margin: 0 5vw;
  width: 100%;
}

.userinfo__right-header {
  margin-top: 100px;

  font-size: 25px;
  line-height: 25px;
  font-weight: 600;
}


.userinfo__table {
  margin-top: 40px;
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed; /* table 내 말줄임 (...) 기능 (1/4) */
}

.userinfo__thead > tr > th {
  padding: 15px 20px;
  text-align: left;
  font-size: 16px;
  line-height: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  color: rgba(108, 108, 108, 1);
  background-color: rgba(252, 239, 242, 1);
}

/* 표 하단 선 */
.userinfo__tbody > tr:last-child {
  border-bottom: 1px solid rgba(205, 205, 205, 1);
}

.userinfo__th01 {
  width: 30%;
}

.userinfo__th02,
.userinfo__th03,
.userinfo__th04,
.userinfo__th05 {
  max-width: 15%;
}

.userinfo__tbody {
  position: relative;
}

.userinfo__tbody > tr {
  border-bottom: 1px solid rgba(205, 205, 205, 1);
}

.userinfo__tbody > tr > td {
  padding: 15px 20px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 300;
  letter-spacing: -1px;


  /* table 내 말줄임 (...) 기능 (2~4/4) */
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.userinfo-table-number {
  display: flex;
  justify-content: center;
}

.userinfo-table-number > div {
  margin-top: 20px;
  margin-right: 10px;

  font-size: 15px;
  cursor: pointer;
}


@media (max-width: 1023px) {

  .userinfo {
    /* margin: 0 auto; */
    /* width: 100%; */
    padding: 0 10px;
    padding-top: 80px;

    min-height: calc(100vh - var(--header-height));

    flex-direction: column;
  }

  .userinfo__left-con {
    width: 100%;
    height: auto;

    border-right: none;
  }

  .userinfo__left-img-txt {
    margin-top: 20px;
  }

  .userinfo__churr-flex-con {
    margin-top: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .userinfo__churr-wrap {
    display: none;
  }

  .userinfo__churr-wrap-mo {
    padding-right: 10%;
    display: flex;
    flex-direction: column;
  }

  .userinfo__churr-wrap-mo > ul {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    height: 30px;
  }

  .userinfo__churr-wrap-mo > ul > img {
    height: 100%;
    object-fit: contain;
  }

  .userinfo__churr-wrap-mo > ul > li {
    margin-left: 10px;
    font-size: 16px;
    font-weight: 400;
    color: rgba(207, 80, 111, 1);
  }

  .userinfo__churr-wrap-mo > li {
    margin-top: 5px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
  }

  .userinfo__charge-con {
    padding-left: 10%;
    margin: 0;
  }


  .userinfo__right-con {
    margin: 0;
    width: 100%;
  }

  .userinfo__right-header {
    margin-top: 40px;

    font-size: 22px;
    line-height: 22px;
  }


  .userinfo__table {
    margin-top: 20px;
    table-layout: auto;
  }

  .userinfo__thead > tr > th {
    padding: 15px 10px;
    /* text-align: left; */
    font-size: 12px;
    line-height: 12px;
    text-align: center;
  }

  .userinfo__th01 {
    max-width: 20%;
  }

  .userinfo__th02,
  .userinfo__th03,
  .userinfo__th04,
  .userinfo__th05 {
    max-width: 15%;
  }

  .userinfo__tbody {
    position: relative;
  }

  .userinfo__tbody > tr {
    border-bottom: 1px solid rgba(205, 205, 205, 1);
  }

  .userinfo__tbody > tr > td {
    padding: 15px 15px;
    font-size: 12px;
    line-height: 12px;
    text-align: center;

    /* table 내 말줄임 (...) 기능 취소! (2~4/4) */
    text-overflow: ellipsis;
    overflow: visible;
    white-space: wrap;
  }

  .userinfo-table-number {
    display: flex;
    justify-content: center;
  }

  .userinfo-table-number > div {
    margin-top: 20px;
    margin-right: 10px;

    font-size: 15px;
    cursor: pointer;
  }


}

/* ========================================================= */

.chat {
  height: 100dvh;
  /*min-height: calc(100dvh - var(--header-height));*/
  /* height: calc(var(--vh, 1vh) * 100) !important; */
}

.chat-sb__con {
  width: 15%;
  /* min-height: 100%; */
}

.chat-sb__new {
  margin: 0 10px;
  margin-top: 30px;
  padding: 10px 20px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  border-radius: 30px;
  background-color: #EBDDE7;
}

.chat-sb__new > a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.chat-sb__list-con {
  margin: 0 10px;
  margin-top: 30px;
}

.chat-sb__list-con > li {
  padding: 15px 20px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  border-bottom: 1px solid #efefef;
}

@media (max-width: 1023px) {
  .chat-sb__con {
    display: none;
  }
}

/* ========================================================= */

.chat-content {
  flex: 1;
  background-color: #F9F9F9;
  height: calc(100vh - var(--header-height));
  overflow-y: scroll;
}

.chat-content__con {
  margin: 0 auto;
  padding-top: 30px;
  max-width: 800px;
  /* padding: 0 40px; */
  width: 100%;
  height: 100%;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
}


.chat-content__talk-con {
  height: 100%;
  overflow-y: scroll;
}

.chat-content__talk-item {
  display: flex;
}

.chat__is_bot {
  flex-direction: row-reverse;
}

/* chat icon */
.chat-content__talk-item {
  margin: 10px 0;
}

.chat-content__talk-item > span {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 65px;
  aspect-ratio: 1/1;

  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
}

.chat-content__talk-item > span > img {
  width: 100%;
  aspect-ratio: 1/1;

  /* filter: invert(100%); */
}

/* chat talking-box */
.chat-content__talk-item > div {
  margin: 0 10px;
  max-width: 50%;

  word-break: break-all;
}

.chat-content__talk-item > div > span {
  margin-bottom: 10px;
  display: block;

  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
}

.chat-content__talk-item-box {
  padding: 15px 20px;
  max-width: 320px;

  border-radius: 10px;
  background-color: #fff;
}

.chat-content__talk-item-box pre {
  margin: 0; /* line-height의 정상적인 적용을 위해 반드시 기입해야 함 */

  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.5px;
  font-weight: 300;
  font-family: 'Noto Sans KR', sans-serif;

  /* pre 태그의 내용이 삐져나옴 방지 */
  width: 100%;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.chat-content__talk-item-box pre p {
  margin: 0; /* line-height의 정상적인 적용을 위해 반드시 기입해야 함 */
}

.chat-content__talk-item-box pre img {
  width: 200px;
}

.chat-user-pre {
  background-color: #ECC0C7 !important;
  color: #fff !important;
  border-bottom-right-radius: 0 !important;
}

.chat-bot-pre {
  border-top-left-radius: 0 !important;
}

.chat-content__talk-btn-con {
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
}

.chat-content__talk-btn-con > li {
  margin-top: 5px;
  margin-right: 5px;
  padding: 5px 10px;

  font-size: 14px;
  line-height: 14px;
  font-weight: 300;
  letter-spacing: -0.5px;
  background: #000000B5;


  background-color: #ECC0C7;
  border-radius: 5px;
  cursor: pointer;
}

.chat-content__circles-con {
  /* display: flex; */
  width: 100%;
}

.chat-content__circles-con > li {
  margin: 5px 0px;
  padding: 0 10px;
  width: 200px;
  height: 35px;

  display: flex;
  /* justify-content: space-between; */
  align-items: center;

  background-color: rgba(207, 80, 111, 1);
  /* border-radius: 50%; */
}

.chat-content__circles-con > li > div:nth-child(1) {
  width: 50px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.71);
}

.chat-content__circles-con > li > div:nth-child(2) {
  flex: 1;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  text-align: center;
}

/* .chat-content__circles-con > li:nth-child(3) > div:nth-child(2) {
  font-size: 22px;
} */

.chat-content__cards-con {
  margin-top: 10px;
}

.chat-content__talk-item:not(:last-child) .chat-content__cards-con {
  display: none;
}

.chat-content__cards-con img {
  height: 150px;
  cursor: pointer;
}

.chat-content__input-wrap {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 108px;
  padding: 30px 0 calc(env(safe-area-inset-bottom) + 30px) 0;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-content__input-wrap input,
.chat-content__input-wrap textarea {
  padding: 12px 60px 12px 20px;
  width: 100%;
  height: 100%;

  border-radius: 30px;

  font-size: 14px;
  letter-spacing: -0.5px;
  font-family: 'Noto Sans KR', sans-serif;

  border: none;
  outline: none;
  background-color: white;

  display: flex;
  align-items: center;
}

.chat-content__input-wrap span {
  position: absolute;
  right: 0;
  margin: auto 0;
  height: 24px;
  aspect-ratio: 1/1;

  border-radius: 50%;
  cursor: pointer;
}

.chat-content__input-wrap span > img {
  position: absolute;
  right: 12px;
  width: 100%;
  aspect-ratio: 1/1;

  filter: invert(70%)
}

@media (max-width: 1023px) {
  .chat-content {
    height: 100%;
  }

  .chat-btn__wrap {
    bottom: 20px;
    right: 20px;

    width: 50px;
    border-radius: 20px;
  }

  .chat-content__con {
    /* top: 50px; */
    /* bottom: 0px; */
    /* right: 0px; */
    padding-top: 80px;

    max-width: 330px;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  .chat-content__talk-item-box {
    padding: 10px 15px;
    /* max-width: 320px; */

    /* border-radius: 10px; */
    /* background-color: #fff; */
  }

  .chat-content__talk-item-box pre {
    margin: 0; /* line-height의 정상적인 적용을 위해 반드시 기입해야 함 */

    font-size: 13px;
    line-height: 20px;
    letter-spacing: -0.5px;
    font-weight: 300;
    font-family: 'Noto Sans KR', sans-serif;

    /* pre 태그의 내용이 삐져나옴 방지 */
    width: 100%;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  .chat-content__talk-item-box pre img {
    width: 180px;
  }

  .chat-content__talk-item > div {
    max-width: 65%;
  }

  .chat-content__circles-con > li {
    width: 180px;
    /* margin: 0 3px; */
    /* padding: 7px 3px 10px 3px; */
    /* width: 55px; */
    /* height: 55px; */

    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: space-between; */
    /* align-items: center; */

    background-color: rgba(207, 80, 111, 1);
    /* border-radius: 50%; */
  }

  .chat-content__circles-con > li > div:nth-child(1) {
    font-size: 10px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.71);
  }

  .chat-content__circles-con > li > div:nth-child(2) {
    font-size: 13px;
    line-height: 20px;
    font-weight: 600;
    color: rgba(255, 255, 255, 1);
    text-align: center;
  }

  .chat-content__circles-con > li:nth-child(3) > div:nth-child(2) {
    font-size: 16px;
  }
}

/* ========================================================= */

.chat-content__ask-con {
  margin-top: 10px;
  width: 150px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #CF506F;
  border-radius: 16px;
  cursor: pointer;
}

.chat-content__talk-item:not(:last-child) .chat-content__ask-con {
  display: none;
}

/* ========================================================= */

.chat-content__loading {
  margin-left: 10px;

  font-size: 12px;
  line-height: 12px;
  color: #8c8c8c;
}

.chat-content__btn-more-con {
  margin: 30px 0;

  /* display: flex; */
  justify-content: center;
  align-items: center;
}

.chat-content__btn-more-con > li {
  margin: 0 10px;
  width: 150px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.5px;
  font-weight: 400;
  color: #fff;

  background-color: #d195ff;
  border-radius: 10px;
  cursor: pointer;
}

@media (max-width: 1023px) {
  .chat-content__btn-more-con > li {
    width: 120px;
    height: 35px;

    font-size: 14px;
    line-height: 14px;
  }
}

/* ========================================================= */

.category {
  flex: 1;
  background-color: #F9F9F9;
  height: calc(100vh - var(--header-height));
  overflow-y: scroll;
}

.category__con {
  margin: 0 auto;
  padding: 30px 0;
  max-width: 900px;
  /* padding: 0 40px; */
  width: 100%;
  min-height: 100%;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
}

/* logo */
.category__logo-wrap {
  margin-top: 3%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.category__logo-wrap > img {
  width: 120px;
}

.category__logo-wrap > span {
  display: inline-block;
  margin-top: 20px;
  font-size: 30px;
  line-height: 30px;
  font-weight: 500;
  letter-spacing: -0.5px;
}

.category__logo-wrap > span > span {
  color: #CF506F;
}

.category__btn-con {
  margin-top: 30px;
  padding-bottom: 48px;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.category__btn-con > li {
  margin: 20px 15px;
  padding: 20px 30px;
  max-width: 250px;
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 10px 30px; */

  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  letter-spacing: -1px;
  text-align: center;

  background-color: #fff;
  border: 1px solid #cfcfcf;
  border-radius: 30px;
  box-shadow: none;
  cursor: pointer;
  transition: 0.5s;

  position: relative;
}

.category__btn-con > li > img {
  margin-left: 5%;
  height: 100%;
}

.category__btn-con > li > div {
  position: absolute;
  top: -30px;
  left: 10px;
  display: none;
  font-weight: 600;
}

.category__btn-clicked {
  background-color: #ECC0C7 !important;
  border: none;
  box-shadow: 2px 3px 3px #afafaf !important;
}

.category__btn-hot {
  background-color: rgba(252, 0, 1, 0.03) !important;
  border: 1px solid rgba(231, 98, 98, 1) !important;
}

.category__btn-hot > div {
  display: block !important;
  color: rgba(231, 98, 98, 1);
}

.category__btn-best {
  background-color: rgba(255, 226, 83, 0.04) !important;
  border: 1px solid rgba(239, 206, 74, 1) !important;
}

.category__btn-best > div {
  display: block !important;
  color: rgba(239, 206, 74, 1);
}

.category__btn-new {
  background-color: rgba(255, 226, 83, 0.04) !important;
  border: 1px solid rgb(37, 186, 63) !important;
}

.category__btn-new > div {
  display: block !important;
  color: rgba(37, 186, 63);
}


.category__input-con {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.category__input-con > li {
  margin-bottom: 15px;
  width: 300px;
  height: 40px;
}

.category__input-con > li input {
  width: 100%;
  height: 100%;

  font-size: 20px;
  line-height: 20px;
  color: #fff;
  text-indent: 20px;

  border: none;
  border-radius: 10px;
  background-color: #c8c8c8;
  box-shadow: 2px 3px 3px #d1d1d1;
}

.category__input-con > li input::placeholder {
  font-size: 20px;
  line-height: 20px;
  color: #fff;
}

.category__start {
  font-size: 20px;
  line-height: 20px;
  color: #fff;

  background-color: #ECC0C7;
  border-radius: 30px;
  box-shadow: 2px 3px 3px #aeaeae;
  cursor: pointer;
}

.category__start > a {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.category__start-before {
  background-color: #afafaf;
  pointer-events: none;
}


@media (max-width: 1023px) {
  .category {
  }

  .category__con {
    padding: 0 10px;
    height: 100%;
    overflow-y: scroll;
  }

  /* logo */
  .category__logo-wrap {
    margin-top: 108px;
  }

  .category__logo-wrap > span {
    /* margin-top: 20px; */
    font-size: 22px;
    line-height: 22px;
    letter-spacing: -1px;
  }

  .category__btn-con {
    margin-top: 30px;
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: center; */
    /* align-items: center; */
    /* flex-wrap: wrap; */
  }

  .category__btn-con > li {
    margin: 15px 5px;
    padding: 5px 10px;
    max-width: 45%;
    /* width: 100%; */
    height: 100px;
    /* display: flex; */
    /* justify-content: space-between; */
    /* align-items: center; */
    /* padding: 10px 30px; */

    font-size: 13px;
    line-height: 18px;
    /* font-weight: 300; */
    /* letter-spacing: -0.5px; */
    /* text-align: center; */

    /* background-color: #fff; */
    /* border: 1px solid #cfcfcf; */
    /* border-radius: 30px; */
    /* box-shadow: none; */
    /* cursor: pointer; */
    /* transition: 0.5s; */
  }

  .category__btn-con > li > img {
    height: 55%;
  }

  .category__btn-con > li > div {
    /* position: absolute; */
    top: -20px;
    left: 10px;
    /* display: none; */
    /* font-weight: 600; */
  }


  .category__input-con {
    /* margin-top: 30px; */
  }

  /* .category__input-con > li {
    margin-bottom: 15px;
    width: 300px;
    height: 55px;
  } */
  .category__input-con > li input {
    font-size: 18px;
    line-height: 18px;
  }

  .category__input-con > li input::placeholder {
    font-size: 18px;
    line-height: 18px;
  }

  .category__start {
    font-size: 18px;
    line-height: 18px;
  }
}

/* ========================================================= */

.category-new__logo-wrap {
  display: flex;
  align-items: center;
}

.category-new__logo-wrap > img {
  width: 60px;
}

.category-new__logo-wrap > span {
  display: inline-block;
  font-size: 30px;
  line-height: 30px;
  font-weight: 500;
  letter-spacing: -0.5px;
  color: #CF506F;
}


/* ========================================================= */

.userinfo__item {
  margin-top: 10px;
  width: 300px;
  display: flex;
}

.userinfo__item:nth-of-type(1) {
  margin-top: 30px;
}

.userinfo__item > li {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.userinfo__item > li:nth-child(1) {
  width: 130px;
}


/* ========================================================= */


.payment {
  /* position: fixed; */
  left: 0;
  top: var(--header-height);
  width: 100%;
  height: calc(100vh - var(--header-height));

  background-color: #fff;
}

.payment__con {
  margin: 0 auto;
  max-width: 900px;
  /* padding: 0 40px; */
  width: 100%;
  height: 100%;
}

.payment__churr-wrap {
  display: flex;
  align-items: center;
  height: 50px;
}

.payment__churr-wrap > img {
  margin-left: 10px;
  margin-right: 5px;
  height: 100%;
  object-fit: contain;
}

.payment__churr-wrap > li > span {
  color: rgba(207, 80, 111, 1);
}

/* logo */
.payment__logo-wrap {
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.payment__logo-wrap > img {
  width: 300px;
}

.payment__logo-wrap > span {
  display: inline-block;
  margin-top: 30px;
  font-size: 23px;
  line-height: 23px;
  font-weight: 400;
  letter-spacing: -0.5px;
  text-align: center;
}

.payment__logo-wrap > span:nth-of-type(2) {
  margin-top: 15px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: -1px;
  color: rgba(161, 161, 161, 1);
}

.payment__logo-wrap > span:nth-of-type(2) > span {
  color: red;
}

.payment__btn-con {
  margin-top: 30px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  border-top: 1px solid #dfdfdf;
}

.payment__btn-con > li:nth-child(1) {
  font-size: 30px;
  line-height: 30px;
  font-weight: 600;
  color: #CF506F;
}

.payment__modal {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 350px;
  width: 100%;
  aspect-ratio: 16/11;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: rgb(244, 228, 228);
  box-shadow: 1px 1px 3px #CF506F;
  border-radius: 20px;
  z-index: 1;
}

.payment__btn-x-wrap {
  position: absolute;
  top: 5%;
  right: 5%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.payment__btn-x-wrap > span {
  margin-right: 10px;
  font-size: 14px;
  font-weight: 300;
}

.payment__btn-x-wrap > img {
  width: 15px;
  height: 15px;
}

.payment__modal > div {
  font-size: 20px;
  line-height: 28px;
  text-align: center;
}

.payment__modal > ul {
  margin-top: 30px;
  text-align: center;
  font-size: 14px;
  font-weight: 300;
}

.payment__modal > ul > li {
  margin-bottom: 10px;
  width: 300px;
  height: 45px;
}

.payment__modal > ul > li > a {
  width: 100%;
  height: 100%;
  padding: 0 10%;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: 500;
  color: #fff;
  border: none;
  background-color: #fff;

  background: #CF506F;
  border-radius: 10px;
  cursor: pointer;
}

.payment__modal > ul > li > a > img {
  margin-right: 20px;
  height: 60%;
}

.payment__modal > ul > li:nth-child(1) > a {
  font-weight: 300;
  display: flex;
  justify-content: space-between;
  color: rgba(98, 98, 98, 1);
  background-color: #fff;
}

.payment__modal > ul > li:nth-child(1) > a > div {
  height: 100%;
  display: flex;
  align-items: center;

  font-size: 14px;
  font-weight: 600;
}

.payment__modal > ul > li:nth-child(1) > a > div > img {
  margin-right: 10px;
  height: 60%;
}

/* .payment__btn-con > li:nth-child(2) {
  margin-top: 15px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 300;
}
.payment__btn-con > li:nth-child(3) {
  margin-top: 20px;
  font-size: 20px;
  line-height: 20px;
  font-weight: 300;
}
.payment__btn-con > li:nth-child(3) > span {
  font-size: 40px;
  line-height: 40px;
  font-weight: 600;
}
.payment__btn-con > li:nth-child(4),
.payment__btn-con > li:nth-child(5),
.payment__btn-con > li:nth-child(6) {
  margin-top: 30px;
  padding: 15px 80px;
  font-size: 22px;
  line-height: 22px;
  font-weight: 600;
  color: #fff;
  background-color: #CF506F;
  border-radius: 30px;
  cursor: pointer;
}
.payment__btn-con > li:nth-child(5) {
  margin-top: 15px;
  background-color: #F38926;
}

.payment__btn-con > li:nth-child(6) {
  margin-top: 15px;
  background-color: #F3DE26;
  color: #CF506F;
}

.payment__coupon {
  margin-top: 15px;
  width: 200px;
}
.payment__coupon > input {
  width: 100%;
  height: 35px;
  font-size: 16px;
  line-height: 16px;
  text-indent: 5px;

  border: 1px solid #afafaf;
  border-radius: 5px;
  outline: none;
  background-color: #fff !important;
}

.payment__coupon-submit {
  margin-top: 30px;
  width: 120px;
  height: 40px;

  font-size: 16px;
  color: #fff;
  border: none;
  border-radius: 5px;

  background: #CF506F;
  cursor: pointer;
} */

.payment__select-con {
  padding-top: 15px;
}

.payment__select-row-con {
  margin-top: 15px;
  display: flex;
}

@media (hover: hover) and (pointer: fine) {
  .payment__select-row-con > li:not(:first-child) {
    margin-left: 30px;
  }
}

.payment__select-row-con > li {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.payment__select-row-con > li > img {
  height: 60px;
}

.payment__select-row-con > li > div.payment__price > ul.payment__price_sale {
  float:center; 
  text-align:center;
}

@media (hover: hover) and (pointer: fine) {
  .payment__select-row-con > li > div.payment__price > ul > li {
    margin-top: -23px;
  }
 }

.payment__select-row-con > li > div.payment__price > ul > li > span:first-child {
  /* margin-left: 10px; */
  width: 123px;
  height: 25px;
  /* display: flex; */
  /* justify-content: center;
  align-items: center; */
  justify-content: space-between;
  align-items: center;
  font-weight: 300;
  font-size: 11.66px;
  color: #707070;
  /* border-radius: 30px; */
  /* background-color: rgba(207, 80, 111, 1); */
  /* direction: row; */
  /* cursor: pointer; */
  text-decoration: line-through;
}

.payment__select-row-con > li > div.payment__price > ul > li > span:last-child {
  margin-left: 5px;
  width: 123px;
  height: 25px;
  /* display: flex; */
  /* justify-content: center;
  align-items: center; */
  font-weight: 600;
  font-size: 21.66;
  color: #FF6B1B;
  /* border-radius: 30px; */
  /* background-color: rgba(207, 80, 111, 1); */
  /* direction: row; */
  /* cursor: pointer; */
  /* text-decoration: line-through; */
}

.payment__select-row-con > li > div.payment__price > span:last-child {
  width: 110px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: #fff;
  border-radius: 30px;
  background-color: rgba(207, 80, 111, 1);
  cursor: pointer;
}

.payment__select-clicked {
  background-color: rgb(127, 0, 32) !important;
}

.payment__select-row-con > li > div > span:nth-child(2) {
  color: rgba(207, 80, 111, 1);
}


.payment__event-con {
  margin-top: 30px;
  padding: 30px 0;
  display: flex;
  align-items: center;
  /* border-top: 0.3px solid #c7c7c7; */
}

.payment__event-title {
  margin-right: 20px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
}

.payment__event-inner-flex {
  display: flex;
  align-items: center;
}

.payment__event-submit-wrap {
  width: 300px;
}

.payment__event-submit-wrap > input {
  width: 100%;
  height: 35px;
  font-size: 16px;
  line-height: 16px;
  text-indent: 5px;

  border: 1px solid #afafaf;
  border-radius: 5px;
  outline: none;
  background-color: #fff !important;
}

.payment__event-btn {
  margin-left: 15px;
  padding: 8px 15px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  color: rgba(207, 80, 111, 1);
  background-color: rgba(255, 226, 83, 1);
  border-radius: 20px;
  word-break: keep-all;
  cursor: pointer;
}

@media (max-width: 1023px) {
  .payment {
    overflow-y: scroll;
  }

  .payment__con {
    padding: 0 10px;
  }

  .payment__churr-wrap {
    margin-left: 10px;
    margin-top: var(--header-height);
    height: 30px;
  }

  .payment__churr-wrap > li {
    font-size: 13px;
  }

  .payment__churr-wrap > img {
    margin-left: 5px;
    margin-right: 3px;
  }

  /* logo */
  .payment__logo-wrap {
    /* margin-top: 7vh; */
  }

  .payment__logo-wrap > img {
    width: 160px;
  }

  .payment__logo-wrap > span {
    margin-top: 15px;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
  }

  .payment__logo-wrap > span:nth-of-type(2) {
    /* margin-top: 15px; */
    font-size: 10px;
    line-height: 18px;
    font-weight: 400;
    /* letter-spacing: -1px; */
    /* color: rgba(161, 161, 161, 1); */
  }

  /* .payment__logo-wrap > span:nth-of-type(2) > span {
    color: red;
  } */
  .payment__btn-con {
    margin-top: 20px;
    padding-top: 20px;
  }

  .payment__btn-con > li:nth-child(1) {
    font-size: 22px;
    line-height: 22px;
    font-weight: 600;
    color: #CF506F;
  }

  /* .payment__btn-con > li:nth-child(2) {
    margin-top: 10px;
  }
  .payment__btn-con > li:nth-child(3) {
    margin-top: 15px;
    margin-bottom: 10px;
  }
  .payment__btn-con > li:nth-child(4),
  .payment__btn-con > li:nth-child(5),
  .payment__btn-con > li:nth-child(6) {
    margin-top: 12px;
    padding: 12px 65px;
    font-size: 20px;
    line-height: 20px;
  }

  .payment__modal {
    top: 50%;
  }   */
  .payment__modal > ul {
    margin-top: 20px;
    font-size: 12px;
  }

  .payment__modal > ul > li {
    margin-bottom: 12px;
    width: 280px;
    height: 42px;
  }

  .payment__modal > ul > li > a {
    font-size: 13px;
  }

  .payment__modal > ul > li:nth-child(1) > a > div {
    font-size: 12px;
  }


  .payment__select-con {
    margin-top: 15px;
  }

  .payment__select-row-con {
    margin-top: 0;
    flex-direction: column;
  }

  .payment__select-row-con > li {
    margin-left: 0;
    border-top: 0.3px solid rgb(162, 162, 162);
  }

  /* .payment__select-row-con:nth-child(2) > li:first-child {
    border-top: 0.3px solid rgb(162, 162, 162);
  } */

  .payment__select-row-con > li {
    padding: 5px 0;
    font-size: 14px;
  }

  .payment__select-row-con > li > img {
    height: 40px;
  }

  .payment__select-row-con > li > span:last-child {
    width: 100px;
    height: 25px;
    font-size: 12px;
    font-weight: 400;
  }

  .payment__select-row-con > li > div {
    margin-left: 15%;
    flex: 1;
  }


  .payment__event-con {
    display: block;
  }

  .payment__event-inner-flex {
    margin-top: 10px;
  }

  .payment__event-submit-wrap {
    width: 100%;
  }
}

/* ========================================================= */


/* logo */
.payment-next__info-con {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;

  margin-top: 5%;
  display: flex;
  flex-direction: column;
}

.payment-next__info-con > div {
  padding: 10px;
  padding-bottom: 30px;
  font-size: 20px;
  font-weight: 400;
  border-bottom: 0.5px solid rgba(112, 112, 112, 1);
}

.payment-next__info-con > div > div:nth-child(2) {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 300;
  color: rgba(112, 112, 112, 1);
}

.payment-next__info-con > span {
  display: inline-block;
  margin-top: 30px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: -1px;
  color: rgba(161, 161, 161, 1);
  text-align: center;
}

.payment-next__info-con > span > span {
  color: red;
}

@media (max-width: 1023px) {
  .payment-next__info-con {
    margin-top: var(--header-height)
  }

  .payment-next__info-con > div {
    padding: 30px;
    font-size: 18px;
  }

  .payment-next__info-con > div > div:nth-child(2) {
    font-size: 16px;
  }

  .payment-next__info-con > span {
    margin-top: 15px;
    font-size: 10px;
    line-height: 18px;
    text-align: center;
  }
}

/* ========================================================= */

.signin {
  position: fixed;
  left: 0;
  top: var(--header-height);
  width: 100%;
  height: calc(100vh - var(--header-height));

  background-color: #fff;
  z-index: 100;
}

.signin__con {
  margin: 0 auto;
  max-width: 900px;
  /* padding: 0 40px; */
  width: 100%;
  height: 100%;
}

.signin__header {
  margin-top: 8vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  font-size: 30px;
  line-height: 30px;
  font-weight: 600;
  color: #CF506F;
}

.signin__social-con {
  margin: 0 auto;
  margin-top: 30px;
  padding-top: 30px;
  max-width: 600px;

  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid #d7d7d7;
}

.signin__social-con > li {
  margin-bottom: 20px;
  padding: 7px;

  max-width: 350px;
  width: 100%;
  height: 56px;
  display: flex;
  /* justify-content: center; */
  align-items: center;

  border-radius: 12px;
  cursor: pointer;
}

.naver_login {
  background-color: #03c75a;
  color: #fff;
}

.naver_icon {
  margin-left: 5%;
  width: 35px;
  height: 35px;
  margin-left: 10px;
  background-size: 30px;
}

.naver_title {
  margin-left: 15%;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
}

.signin__social-qna {
  display: flex;
  justify-content: center;

  font-weight: 500;
  color: #535353;

  background-color: #f7dbdb;
  border: 4px solid #eac7c7;
}

/* ========================================================= */

.footer {
  /* margin-top: 30px; */
  padding: 20px 0;

  /* custom */
  background: #e8e8e8;
  color: rgba(0, 0, 0, 0.3);

}

.footer__con {
  /* padding: var(--page-padding-pc); */
  margin: 0 auto;
  width: 100%;
  /* max-width: var(--page-width); */

  /* display: flex; */
  position: relative;
}

.footer__logo {
  margin: 10px 8vw 30px 8vw;
  width: 180px;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  font-size: 20px;
  /* color: white; */

  font-weight: bold;
}

.footer__con > hr {
  background: rgba(0, 0, 0, 0.1);
  height: 2px;
  border: 0;
}

.footer__logo > img {
  width: 100%;
}

.footer__txt-con {
  margin: 30px 8vw;
}

.footer__txt01 {
  padding-bottom: 10px;
  font-size: 14px;
  line-height: 22px;
}

.footer__txt02 {
  font-size: 14px;
}

.footer__icon-con {
  display: flex;
  position: absolute;
  right: 40px;
  bottom: 30px;
}

.footer__icon-con > a {
  margin-left: 15px;
  width: 40px;
}

.footer__icon-con > a > img {
  width: 100%;
}

.footer__law-con {
  display: flex;
  position: absolute;
  right: 40px;
  bottom: 0px;
  font-size: 13px;
  line-height: 13px;
}

.footer__law-con > a {
  padding: 0 10px;
}

.footer__law-con > a:not(:last-child) {
  border-right: 1px solid #afafaf;
}

@media (max-width: 1023px) {
  .footer {
    /* padding-bottom: 50px; */
  }

  .footer__con {
    /* padding: var(--page-padding-tab); */
    flex-direction: column;
  }

  .footer__logo {
    /* margin-bottom: 10px; */
    /* justify-content: left; */
    font-size: 18px;
  }

  .footer__txt01 {
    font-size: 12px;
    line-height: 20px;
  }

  .footer__txt02 {
    font-size: 14px;
  }

  .footer__icon-con {
    right: 20px;
    bottom: auto;
    top: 0;
  }

  .footer__law-con {
    right: 20px;
    bottom: -30px;
    font-size: 11px;
    letter-spacing: -1px;
  }
}

/* ========================================================= */

.license__con {
  padding: var(--page-padding-pc);
  margin: 0 auto;
  width: 100%;
  max-width: var(--page-width);
}

.license__grid-con {
  margin: 100px 0;
  display: flex;
  flex-wrap: wrap;
}

.license__grid-item {
  margin: 30px 10px 30px 10px;
  width: calc((100% - 2 * 20px) / 2);
  display: flex;
  flex-direction: column;
  /* cursor: pointer; */
  position: relative;
}

.license__source-name {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -1px;
  color: #428ef9;
  cursor: pointer;
}

.license__source-url {
  letter-spacing: -1px;
  opacity: 0.7;
  font-weight: 400;
  color: darkblue;
  text-decoration: underline;
}

.license__source-type {
  letter-spacing: -1px;
  opacity: 0.7;
}


.license__source-writer {
  font-weight: 500;
  letter-spacing: -1px;
  line-height: 22px;
}

.license__txt-item {
  padding: 30px 0;
  border-top: 1px solid #afafaf;
}

.license__txt-title {
  font-size: 25px;
  font-weight: 600;
  text-align: center;
}

.license__txt-sub {
  margin-top: 30px;
  font-size: 14px;
  font-weight: 300;
  color: #222222;
}


@media (max-width: 1023px) {
  .license__con {
    padding: var(--page-padding-tab);
  }

  .license__txt-sub {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .license__con {
    padding: var(--page-padding-mo);
  }

  .license__grid-item {
    margin: 15px 0px 15px 0px;
    margin-bottom: 15px;
    width: calc((100% - 2 * 10px) / 2);
  }

  .license__grid-item:nth-child(2n-1) {
    margin-right: 20px;
  }

  .license__source-name {
    font-size: 18px;
  }


  .license__source-type {
    font-size: 15px;
  }

  .license__source-writer {
    font-size: 15px;
    font-weight: 400;
  }


}

@media (max-width: 480px) {
}
